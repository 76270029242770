import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  inject
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { INSTANCE_CODE } from '@konnektu/tokens';
import { injectTenantNavigate } from './tenant-router';

@Directive({
  selector: '[knkTenantRouterLink]',
  standalone: true,
  hostDirectives: [RouterLink]
})
export class TenantRouterLinkDirective {
  private readonly tenantCode = inject(INSTANCE_CODE);

  private readonly router = inject(Router);

  private readonly tenantNavigate = injectTenantNavigate();

  private readonly elementRef = inject(ElementRef);

  @HostBinding('attr.href')
  protected href?: string;

  @Input()
  set knkTenantRouterLink(commands: any[] | string | null | undefined) {
    if (commands) {
      this.commands = Array.isArray(commands)
        ? [this.tenantCode, ...commands]
        : [this.tenantCode, commands];
      this.updateHref();
    }
  }

  @Input()
  set knkTenantRouterLinkQueryParams(params: Record<string, any>) {
    this.queryParams = params;
    this.updateHref();
  }

  private commands: any[] = [];

  private queryParams?: Record<string, any>;

  private updateHref() {
    const url = this.router.createUrlTree(this.commands, {
      queryParams: this.queryParams
    });
    if (url) {
      this.href = url.toString();
    }
  }
}
