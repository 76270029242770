import { inject } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { INSTANCE_CODE } from '@konnektu/tokens';

export function injectTenantNavigate() {
  const tenantCode = inject(INSTANCE_CODE);
  const router = inject(Router);
  return (commands: any, extras?: NavigationExtras) =>
    router.navigate([tenantCode, ...commands], extras);
}
