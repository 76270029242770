import { createContextLogger } from '@konnektu/helpers';

export interface BootstrapTenantOptions {
  tenantCodeProvider: () => string | undefined;
  checkTenant: (code: string) => Promise<boolean>;
  tenantNotFound: (code: string | undefined) => Promise<void>;
  tenantFound: (code: string) => Promise<void>;
}

export async function bootstrapTenantApplication({
  tenantCodeProvider,
  checkTenant,
  tenantFound,
  tenantNotFound
}: BootstrapTenantOptions) {
  const logger = createContextLogger('bootstrapApplication');
  const tenantCode = tenantCodeProvider();

  if (tenantCode) {
    logger.debug(`Running bootstrap on ${tenantCode}`);
    try {
      const instanceExist = await checkTenant(tenantCode);
      if (instanceExist) {
        logger.debug(`${tenantCode} exists. Bootstrapping tenant application`);
        await tenantFound(tenantCode);
      } else {
        logger.error(
          'Instance was checked but it is not existing. Bootstrapping not found'
        );
        await tenantNotFound(tenantCode);
      }
    } catch (err) {
      logger.error('Instance check failed. Bootstrap error', err);
      await tenantNotFound(tenantCode);
    }
  } else {
    logger.debug('Tenant code not found. Stopping bootstrap');
    await tenantNotFound(tenantCode);
  }
}
